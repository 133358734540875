<template>
  <div>
    <div class="row mt-4 mb-4">
      <h1 class="col-sm-12 text-right">Solicitudes</h1>
    </div>

    <div class="row">
      <div class="col-sm-12">

        <div class="row">
          <div class="col-sm-3 offset-sm-6">
            <div  class="input-group mb-3">
              <input v-model="buscar" type="text" class="form-control" placeholder="Buscar ID...">
              <div class="input-group-append">
                <button class="btn btn-primary">Buscar</button>
              </div>
            </div>
          </div>
          <h2 class="col-sm-3">
            <select v-model="tipo_etapas" name="tipo_etapa" id="tipo_etapa" class="form-control">
              <option value="1">Ver solicitudes Activas</option>
              <option value="2">Ver solicitudes Pausadas</option>
              <option value="3">Ver solicitudes Cerradas</option>
              <option value="0">Ver solicitudes No viables</option>
              <option value="a">Ver todas las solicitudes</option>
            </select>
          </h2>
        </div>

        <div v-if="solicitudes_paginadas.length == 0" class="row">
          <div class="col-sm-12">No hay solicitudes</div>
        </div>

        <template v-else>
        <div  class="row">
          
          <div v-for="solicitud in solicitudes_paginadas" :key="solicitud.id" class="col-sm-4 col-md-3">
            <div class="card border-info mb-3">
              <h4 class="card-header">Solicitud: {{ solicitud.solicitud }}</h4>
              <div class="card-body">
                <p class="card-text">Nombre: {{ solicitud.nombre }}</p>
                <p class="card-text">Entidad: {{ solicitud.estado }}</p>
                <p class="card-text">Etapa: {{ buscar_etapa(ult_sla(solicitud.sla).tipo) }}</p>
                <p class="card-text">Fecha: {{ $moment(ult_sla(solicitud.sla).created_at).format('MM MMMM YYYY HH:mm') }}</p>
                <p class="card-text">Estatus valuación: {{ estatus_usuario(solicitud.estatus_valuacion) }}</p>
                <p class="card-text">Estatus notario: {{ estatus_usuario(solicitud.estatus_notario) }}</p>
                <p class="card-text">Estatus general: {{ estatus(solicitud.estatus) }}</p>
              </div>
              <div class="card-footer text-right">
                <router-link :to="{name: 'formalizacion_V2_administracion_solicitudes_detalle', params:{id: solicitud.id }}" class="btn btn-outline-secondary">Ver solicitud</router-link>
              </div>
            </div>
          </div>

        </div>

        <div v-if="paginas_mostrar.length > 1" class="row">
          <div class="col-sm-12">
            <nav>
              <ul class="pagination">
                <li class="page-item"><a class="page-link" href="#" @click.prevent="pagina_anterior">Anterior</a></li>
                <template v-for="num_pagina in paginas_mostrar">
                <li :class="'page-item'+(num_pagina == pagina_actual ? ' pagina_actual' : '')"><a class="page-link" href="#" @click.prevent="pagina_actual = num_pagina">{{ num_pagina }}</a></li>
                </template>
                <li class="page-item"><a class="page-link" href="#" @click.prevent="pagina_siguiente">Siguiente</a></li>
              </ul>
            </nav>
          </div>
        </div>  
        </template>

      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import apiSolicitudes from '@/apps/formalizacion/api/v2/solicitudes'
  import apiEtapas from '@/apps/formalizacion/api/v2/etapas'

  export default {
    components: {
      
    }
    ,data() {
      return {
        solicitudes: []
        ,etapas: []
        ,tipo_etapas: 1
        ,por_pagina: 12
        ,pagina_actual: 1
        ,buscar: null
      }
    }
    ,mounted() {
      this.obtener_etapas();
      // this.$log.info('paginator',this.$paginator)
    }
    ,methods: {
      async obtener_solicitudes() {
        try {
          this.solicitudes = (await apiSolicitudes.obtener_solicitudes()).data;
          // this.solicitudes = this.solicitudes_fake;
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,async obtener_etapas() {
        try {
          this.etapas = (await apiEtapas.obtener_etapas()).data;

          this.obtener_solicitudes();
        }catch(e) {
          this.$log.info('Error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,ult_sla(slas) {
        return slas.length > 0 ? slas[slas.length-1] : {tipo: null};
      }
      ,buscar_etapa(tipo) {
        let etapa = {
          etapa: 'NA'
        };

        for(let i=0; i<this.etapas.length; i++) {
          if (tipo == this.etapas[i].codigo) {
            etapa = this.etapas[i];
            i=this.etapas.length;
          }
        }

        return etapa.etapa;
      }
      ,estatus_usuario(estatus) {
        switch(estatus) {
          case 1:
            return 'Activo';
          case 2:
            return 'Cerrado';
        }
      }
      ,estatus(estatus) {
        switch(estatus) {
          case 1:
            return 'Activo';
          case 2:
            return 'Pausado';
          case 3:
            return 'Cerrado';
          case 0:
            return 'Inválido';
        }
      }
      ,pagina_anterior() {
        if (this.pagina_actual > 1)
          this.pagina_actual -= 1;
      }
      ,pagina_siguiente() {
        if (this.pagina_actual < this.$paginator.total_pages(this.solicitudes_visibles, this.por_pagina))
          this.pagina_actual += 1;
      }
    }
    ,computed: {
      solicitudes_visibles() {
        let solicitudes = [];

        if (this.tipo_etapas != 'a')
          this.solicitudes.forEach(solicitud => {
            if (solicitud.estatus == this.tipo_etapas)
              solicitudes.push(solicitud);
          })
        else
          solicitudes = this.solicitudes;

        if (this.buscar && this.buscar != '') {
          let busqueda = [];
          for(let i=0; i<solicitudes.length; i++) {
            if (solicitudes[i].solicitud.toString().indexOf(this.buscar) != -1) {
              busqueda.push(solicitudes[i]);
            }
          }

          solicitudes = busqueda;
        }

        return solicitudes;
      }
      ,solicitudes_paginadas() {
        return this.$paginator.paginate_data(this.solicitudes_visibles, this.pagina_actual, this.por_pagina);
      }
      ,paginas_mostrar() {
        return this.$paginator.paginate_navigation(this.solicitudes_visibles, this.pagina_actual, this.por_pagina, 3);
      }
      // ,solicitudes_fake() {
      //   let solicitud = this.$helper.fromJson('{"id":144,"oficina_id":3,"solicitud":24408,"nombre":"MANUEL ALFONSO TRUJILLO MUÃ‘OZ","email":"nemutagk@gmail.com","calle":"Mariano Matamoros","colonia":"Monterrey Centro","estado":"","municipio":"Monterrey","cp":"64000","fecha_firma":null,"fecha_visita_valuacion":null,"costo_avaluo":"3500.00","monto_complementario":"2000.00","monto_total":"5500.00","monto_comision":"1500.00","carga_factura_at":null,"caducidad_clg_at":null,"documentacion_viable_at":null,"usuario_inviable":null,"razon_inviable":null,"solicitud_inviable_at":null,"estatus_valuacion":2,"estatus_notario":1,"estatus":1,"created_at":"2022-03-21T22:25:52.000000Z","updated_at":"2022-06-05T19:19:08.000000Z","sla":[],"usuarios":[{"id":20,"empresa_id":1,"account_id":145,"nombre":"Jose Cabrera 1","email":"jcabrera+forma.evaluador@bienparabien.com","tipo":1,"origen":null,"estatus":1,"created_at":"2022-02-14T22:07:31.000000Z","updated_at":"2022-02-14T22:07:31.000000Z","deleted_at":null}]}');
      //   let solicitudes = [];

      //   let num_solicitud = 2100;
      //   for(let i=0; i<1000; i++) {
      //     let tmp = this.$helper.clone(solicitud);
      //     tmp.id = tmp.id+i;
      //     tmp.solicitud = (num_solicitud + i);
      //     tmp.estatus = parseInt(this.$helper.strRand(1,null,'0123'))
      //     solicitudes.push(tmp);
      //   }

      //   return solicitudes;
      // }
    }
    ,watch: {
      tipo_etapas() {
        this.pagina_actual = 1;
      }
    }
  }
</script>

<style lang="scss" scope>
  .card {
    .card-body {
      p {
        margin: 5px;
        padding: 5px 10px;
      }

      p:nth-child(2n+0) {
        background-color: #E6E6E6
      }
    }
  }

  .card-body {
    .list-item {
      padding: .5rem 0;
      &:nth-child(2n+1) {
        background-color: #E6E6E6;
      }

      .text-right {
        font-weight: bolder;
      }
    }
  }

  .pagina_actual {
    a {
      background-color: #C4E5C5;
    }
  }
</style>