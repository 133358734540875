import Vue from 'vue'
import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_FORMALIZACION+'/v2');

export default {
  obtener_etapas() {
    return http.get(`/etapas`);
  }

  ,crear_etapa(payload) {
    return http.post(`/etapas`, payload);
  }

  ,editar_etapa(id, payload) {
    return http.put(`/etapas/${id}`, payload);
  }

  ,eliminar_etapa(id) {
    return http.delete(`/etapas/${id}`);
  }
}